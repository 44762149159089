import React from "react";
import { Subbanner } from "../Subbanner";
import { AboutContent } from "./AboutContent";
import { AboutStats } from "./AboutStats";
import { VisionMision } from "./VisionMision";

export const About = () => {
  return (
    <>
      <Subbanner />
      <AboutContent />
      <VisionMision />
      <AboutStats />
    </>
  );
};
