import React from "react";
import { Subbanner } from "../Subbanner";
import Taj from "../../Images/clients/taj.png";
import Ganga from "../../Images/clients/ganga-hospitals.png";
import Courier from "../../Images/clients/professional-couriers.png";
import Precot from "../../Images/clients/precot.png";
import Psgindustries from "../../Images/clients/psg-industries.png";
import Psghospitals from "../../Images/clients/psg-hospitals.png";
import Rathinam from "../../Images/clients/rathinam-college.png";
import Snmv from "../../Images/clients/snmv-college.png";
import Hindustan from "../../Images/clients/hindustan.png";
import Lulu from "../../Images/clients/lulu.png";
import Pothys from "../../Images/clients/pothys.png";
import Chennaisilks from "../../Images/clients/chennai-silks.png";
import Bakgiyam from "../../Images/clients/bakgiyam.jpg";
import Alpha from "../../Images/clients/alpha.jpg";
import Hoc from "../../Images/clients/hoc.jpg";
import Able from "../../Images/clients/able.jpg";
import Koso from "../../Images/clients/koso.jpg";
import Geco from "../../Images/clients/geco.jpg";

export const Clients = () => {
  const clients = [
    { src: Taj, alt: "Taj Hotels" },
    { src: Ganga, alt: "Ganga Hospitals" },
    { src: Courier, alt: "The Professional Couriers" },
    { src: Precot, alt: "Precot Mills" },
    { src: Psgindustries, alt: "Psg Industries" },
    { src: Psghospitals, alt: "Psg Hospitals" },
    { src: Rathinam, alt: "Rathinam College" },
    { src: Snmv, alt: "SNMV College" },
    { src: Hindustan, alt: "Hindustan College" },
    { src: Lulu, alt: "Lulu Hyper Market" },
    { src: Pothys, alt: "Pothys" },
    { src: Chennaisilks, alt: "Chennai Silks" },
    { src: Bakgiyam, alt: "Bakgiyam" },
    { src: Alpha, alt: "Alpha" },
    { src: Hoc, alt: "Hoc" },
    { src: Able, alt: "Able" },
    { src: Koso, alt: "Koso" },
    { src: Geco, alt: "Geco" },
  ];

  return (
    <>
      <Subbanner />
      <section className="client section">
        <div
          className="container section-title aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>Our Clients</h2>
          <p>Trusted by Industry Leaders, Driven by Excellence</p>
        </div>
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row g-0 clients-wrap">
            {clients.map(({ src, alt }, index) => (
              <div
                className="col-xl-3 col-md-4 col-sm-6 client-logo"
                key={index}
              >
                <img src={src} className="img-fluid" alt={alt} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
