import React from "react";
import AboutImg from "../../Images/about.jpg";
import BASE_URLS from "../../apiConfig.js";
import { Link } from "react-router-dom";

export const AboutContent = () => {
  const WebUrl = BASE_URLS.WebUrl;
  return (
    <section className="about section">
      <div className="container">
        <div className="row position-relative gy-4">
          <div
            className="col-lg-7 about-img"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src={AboutImg} className="img-fluid" alt="VM Petro Products" />
          </div>

          <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
            <h2 class="inner-title">
              About <span>VM</span> Petro Products
            </h2>
            <div className="our-story">
              <h4>Est 1976</h4>
              <h3 className="pb-3">Our Story</h3>
              <p className="fw-bold">
                <big>
                  VM Petro Products is one of the well-developed industrial
                  lubricant dealers of Coimbatore.
                </big>
              </p>
              <p>
                We specialize in industrial lubricants, specialty lubricants,
                greases, automotive engine oils, and door-to-door deliveries. As
                of today, VM Petro Products offers a wide range of products,
                including{" "}
                <b>
                  BALMOREL, KIKER, HPCL, SERVO, ONWO, MAK, CASTROL, SHELL, and
                  VALVOLINE
                </b>
                . We ensure our customers receive the right product at the right
                time, keeping customer satisfaction as our top priority.
              </p>
              <div class="d-flex pt-3">
                <Link to={`${WebUrl}contact-us`} class="btn-get-started">
                  Reach Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
