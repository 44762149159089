import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import BASE_URLS from "./apiConfig.js";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";
import { Preloader } from "./Components/Preloader";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./Components/Home/Home";
import { About } from "./Components/About/About";
import { Clients } from "./Components/Clients/Clients.jsx";
import { Contact } from "./Components/Contact/Contact.jsx";
import { Products } from "./Components/Products/Products.jsx";
import { NotFound } from "./404.js";

function App() {
  const WebUrl = BASE_URLS.WebUrl;
  const [loader, setLoader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should happen only once
    });
  }, []);

  useEffect(() => {
    setLoader(true); // Set loader to true on route change
    const timer = setTimeout(() => {
      setLoader(false); // Hide loader after a delay
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [location.pathname]);

  return (
    <>
      <Header />
      {loader ? (
        <Preloader />
      ) : (
        <Routes>
          <Route exact path={`${WebUrl}`} element={<Home />} />
          <Route path={`${WebUrl}about-us`} element={<About />} />
          <Route path={`${WebUrl}products`} element={<Products />} />
          <Route path={`${WebUrl}clients`} element={<Clients />} />
          <Route path={`${WebUrl}contact-us`} element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      <Footer />
    </>
  );
}

export default App;
