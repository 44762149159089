import React from "react";
import Hydraulic from "../../Images/oil-product.jpg";
import Cutting from "../../Images/cutting-oil.jpg";
import Grease from "../../Images/grease-oil.jpg";
import Gear from "../../Images/gear-oil.jpg";
import Transform from "../../Images/transform-oil.jpg";

export const ProductList = () => {
  const products = [
    {
      title: "Hydraulic Oil & Waylube",
      description:
        "Our high-quality hydraulic oils ensure optimal performance and long-lasting protection for hydraulic systems in industrial machinery. Waylube oils provide superior lubrication to machine tool slideways, preventing wear and enhancing precision.",
      img: Hydraulic,
      alt: "Hydraulic Oil & Waylube",
      order: "left",
    },
    {
      title: "Cutting Oil",
      description:
        "Formulated to improve machining efficiency, our cutting oils offer excellent cooling and lubrication during metal cutting processes. They reduce tool wear, enhance surface finish, and extend tool life in metalworking applications.",
      img: Cutting,
      alt: "Cutting Oil",
      order: "right",
    },
    {
      title: "Speciality Grease",
      description:
        "Our speciality greases provide exceptional protection against friction, corrosion, and high temperatures, making them ideal for both automotive and industrial applications requiring advanced performance.",
      img: Grease,
      alt: "Speciality Grease",
      order: "left",
    },
    {
      title: "Transformer Oil",
      description:
        "Designed to provide insulation and cooling in transformers, our transformer oils ensure high dielectric strength and efficient heat dissipation, extending the life and reliability of transformers.",
      img: Transform,
      alt: "Transformer Oil",
      order: "right",
    },
    {
      title: "Industrial Gear Oil",
      description:
        "Industrial gear oils offer superior protection against wear, corrosion, and oxidation in heavy-duty machinery, enhancing the longevity and efficiency of equipment under demanding conditions.",
      img: Gear,
      alt: "Industrial Gear Oil",
      order: "left",
    },
  ];

  return (
    <section className="about-products section">
      {products.map((product, index) => (
        <div
          key={index}
          className={`container py-3 mb-5 ${product.order}`}
          data-aos="fade-up"
        >
          <div className="row gx-0">
            {product.order === "left" ? (
              <>
                <div
                  className="col-lg-6 col-md-7 order-last order-sm-first d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="content">
                    <h2>{product.title}</h2>
                    <p>{product.description}</p>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-5 order-first order-sm-last d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={product.img}
                    className="img-fluid"
                    alt={product.alt}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="col-lg-6 col-md-5 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={product.img}
                    className="img-fluid"
                    alt={product.alt}
                  />
                </div>
                <div
                  className="col-lg-6 col-md-7 d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="content">
                    <h2>{product.title}</h2>
                    <p>{product.description}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};
