import React from "react";
import { Subbanner } from "../Subbanner";
import { ProductList } from "./ProductList";

export const Products = () => {
  return (
    <>
      <Subbanner />
      <ProductList />
    </>
  );
};
