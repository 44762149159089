import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";

// Import your client images
import Bpcl from "../../Images/brands/bpcl.jpg";
import Hpcl from "../../Images/brands/hp.jpg";
import Servo from "../../Images/brands/servo.jpg";
import Kiker from "../../Images/brands/kiker.jpg";
import Kyros from "../../Images/brands/kyros.jpg";
import Ybi from "../../Images/brands/ybi.jpg";

const clients = [
  { src: Bpcl, alt: "BPCL" },
  { src: Hpcl, alt: "HPCL" },
  { src: Servo, alt: "SERVO" },
  { src: Kiker, alt: "KIKER" },
  { src: Kyros, alt: "KYROS" },
  { src: Ybi, alt: "YBI" },
];

const Clientslider = () => {
  return (
    <section className="clients">
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Top Brands</h2>
        <p>Trusted Names Driving Quality and Performance Across Industries</p>
      </div>
      <div className="container" data-aos="zoom-in">
        <Swiper
          loop={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          slidesPerView={2}
          modules={[Autoplay]}
          className="swiper"
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 40 },
            1024: { slidesPerView: 6, spaceBetween: 50 },
          }}
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index}>
              <img src={client.src} className="img-fluid" alt={client.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Clientslider;
