import React, { useEffect, useState } from "react";
import Logo from "../Images/logo.png";
import BASE_URLS from "../apiConfig";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export const Header = () => {
  const WebUrl = BASE_URLS.WebUrl;
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const location = useLocation(); // Use the useLocation hook

  const isActiveLink = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleScroll = () => {
    const body = document.querySelector("body");
    const header = document.querySelector("#header");

    if (!header) return;

    const isSticky =
      header.classList.contains("scroll-up-sticky") ||
      header.classList.contains("sticky-top") ||
      header.classList.contains("fixed-top");

    if (isSticky) {
      if (window.scrollY > 100) {
        body.classList.add("scrolled");
      } else {
        body.classList.remove("scrolled");
      }
    }
  };

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
    document.body.classList.toggle("mobile-nav-active");
  };

  // Effect to handle scroll and load events
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <Link to={WebUrl} className="logo d-flex align-items-center">
            <img src={Logo} alt="Kiker Lubricants" className="img-fluid" />
          </Link>

          <nav
            className={`navmenu ${
              isMobileNavActive ? "mobile-nav-active" : ""
            }`}
          >
            <ul>
              <li>
                <Link to={WebUrl} className={isActiveLink(WebUrl)}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={`${WebUrl}about-us`}
                  className={isActiveLink(`${WebUrl}about-us`)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to={`${WebUrl}products`}
                  className={isActiveLink(`${WebUrl}products`)}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to={`${WebUrl}clients`}
                  className={isActiveLink(`${WebUrl}clients`)}
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to={`${WebUrl}contact-us`}
                  className={isActiveLink(`${WebUrl}contact-us`)}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {/* Mobile nav toggle button */}
            <i
              className={`mobile-nav-toggle d-xl-none bi ${
                isMobileNavActive ? "bi-x" : "bi-list"
              }`}
              onClick={toggleMobileNav}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
};
