import React from "react";
import CountUp from "react-countup";

export const AboutStats = () => {
  const stats = [
    {
      icon: "bi-emoji-smile color-blue",
      end: 1000,
      suffix: "+",
      label: "Happy Clients",
      delay: 100,
    },
    {
      icon: "bi-calendar-check color-orange",
      end: 50,
      suffix: "+",
      label: "Years of Service",
      delay: 200,
    },
    {
      icon: "bi-droplet color-green",
      end: 50,
      suffix: "+",
      label: "Lubricant Products",
      delay: 300,
    },
    {
      icon: "bi-buildings color-pink",
      end: 30,
      suffix: "+",
      label: "Various Industries",
      delay: 400,
    },
  ];

  return (
    <section className="stats-counter section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Stats</h2>
        <p>
          Key Metrics Highlighting Our Commitment to Excellence Across
          Industries
        </p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay={stat.delay}
            >
              <div className="stats-item d-flex align-items-center w-100 h-100">
                <i className={`bi ${stat.icon} flex-shrink-0`}></i>
                <div>
                  <span>
                    <CountUp delay={1} end={stat.end} suffix={stat.suffix} />
                  </span>
                  <p>{stat.label}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
