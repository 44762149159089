import React from "react";
import BASE_URLS from "../../apiConfig.js";
import AboutImage from "../../Images/home-about.jpg";
import { Link } from "react-router-dom";

export const HomeAbout = () => {
  const WebUrl = BASE_URLS.WebUrl;
  return (
    <section className="hero get-started about-home section">
      <div className="container">
        <div className="row justify-content-between gy-4">
          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <div className="content">
              <h3>Welcome to VM Petro Products</h3>
              <p className="fw-bold">
                <big>
                  Established in 1976, VM Petro Products is one of the
                  well-developed industrial lubricant dealers of Coimbatore.
                </big>
              </p>
              <p>
                We specialize in industrial lubricants, specialty lubricants,
                greases, automotive engine oils, and door-to-door deliveries. As
                of today, VM Petro Products offers a wide range of products,
                including{" "}
                <b>
                  BALMOREL, KIKER, HPCL, SERVO, ONWO, MAK, CASTROL, SHELL, and
                  VALVOLINE
                </b>
                . We ensure our customers receive the right product at the right
                time, keeping customer satisfaction as our top priority.
              </p>
              <div className="d-flex">
                <Link to={`${WebUrl}contact-us`} className="btn-get-started">
                  View More
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="zoom-out" data-aos-delay="200">
            <div className="about-img-right">
              <img
                src={AboutImage}
                alt="About VM Petro Products"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
