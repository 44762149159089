import React from "react";
import { Subbanner } from "../Subbanner";
import { ContactForm } from "./ContactForm";
import { ContactWidgets } from "./ContactWidgets";

export const Contact = () => {
  return (
    <>
      <Subbanner />
      <section className="contact section light-background">
        <div
          className="container section-title aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>Get in Touch with Us</h2>
          <p>
            Whether you prefer to call, email, or visit us in person, we're
            always ready to assist you.
          </p>
        </div>
        <div className="container" data-aos="fade" data-aos-delay="100">
          <div className="row g-4">
            <div className="col-lg-4">
              <ContactWidgets />
            </div>
            <div className="col-lg-8">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <section className="contact section">
        <div
          className="container section-title aos-init aos-animate"
          data-aos="fade-up"
        >
          <h2>Find us on Google Maps</h2>
          <p>
            Need directions? Use Google Maps to easily navigate to us and enjoy
            a smooth journey to your destination.
          </p>
        </div>
        <div className="container" data-aos="fade" data-aos-delay="100">
          <div className="row gy-4">
            <div className="col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.3205213195965!2d76.95671167587041!3d11.014560954754469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859e3556a02f9%3A0x7a386b4e647ee93e!2sVM%20PETRO%20PRODUCTS!5e0!3m2!1sen!2sin!4v1727430575492!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google location for Kiker Lubricants"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
