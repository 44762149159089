import React, { useState, useEffect } from "react";
import Tulips from "../Images/tulips.png";
import { Link } from "react-router-dom";
import BASE_URLS from "../apiConfig.js";

export const Footer = () => {
  const WebUrl = BASE_URLS.WebUrl;
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Scroll-to-top function
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer dark-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <Link to="index.html" className="logo d-flex align-items-center">
                <span className="sitename">VM Petro Products</span>
              </Link>
              <div className="footer-contact pt-3">
                <p>90, Swarnambika Layout, Ram Nagar</p>
                <p>Coimbatore - 641 009.</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+91 95976 01333</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>kikerlubricants@gmail.com</span>
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}`}>Home</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}about-us`}>About us</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Products</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}clients`}>Clients</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}contact-us`}>Contact us</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Our Products</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Hydraulic Oil & Waylube</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Cutting Oil</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Speciality Grease</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Transformer Oil</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link to={`${WebUrl}products`}>Industrial Gear Oil</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-12 footer-links">
              <h4>Follow us</h4>
              <p>
                Follow us on social media to stay updated on the latest news,
                offers, and insights from Kiker Lubricants.
              </p>
              <div className="social-links d-flex mt-4">
                <Link to="https://x.com/" target="_blank">
                  <i className="bi bi-twitter-x"></i>
                </Link>
                <Link to="https://www.facebook.com/" target="_blank">
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link to="https://www.instagram.com/" target="_blank">
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link to="https://www.linkedin.com/" target="_blank">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container copyright mt-4">
          <div className="row gy-4">
            <div className="col-lg-6 col-md-6 text-center text-md-start text-lg-start text-xl-start text-xxl-start">
              <p>
                © <span>Copyright</span>{" "}
                <strong className="px-1 sitename">VM Petro Products</strong>{" "}
                <span>All Rights Reserved</span>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 text-center text-md-end text-lg-end text-xl-end text-xxl-end">
              <div className="credits">
                Design & Developed by{" "}
                <Link
                  to="https://tulipsmedia.com/"
                  target="_blank"
                  rel="noreferrer"
                  title="Tulips Media"
                >
                  &nbsp;
                  <img
                    src={Tulips}
                    alt="Tulips Media"
                    className="img-fluid"
                    width="30px"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Scroll Top Button */}
      {showScrollTop && (
        <Link
          to="#"
          className="scroll-top active d-flex align-items-center justify-content-center"
          onClick={scrollToTop}
        >
          <i className="bi bi-arrow-up-short text-white"></i>
        </Link>
      )}
    </>
  );
};
