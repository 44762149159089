import React from "react";

export const Services = () => {
  const services = [
    {
      icon: "bi-buildings",
      title: "Extensive Experience",
      description: (
        <>
          With over 25 years in the industry, we have a proven track record of
          delivering quality service to our diverse clientele. Our deep
          understanding of the market enables us to meet and exceed your
          expectations.
        </>
      ),
      delay: 100,
    },
    {
      icon: "bi-wrench-adjustable-circle",
      title: "Diverse Service",
      description: (
        <>
          Whether it’s hospitality or lubricants, our comprehensive range of
          services ensures that all your needs are met under one roof. We
          operate two hotels under the brand <b>'HOTEL GRAND PLAZA'</b> and
          manage multiple Bharat Petroleum retail outlets.
        </>
      ),
      delay: 200,
    },
    {
      icon: "bi-people",
      title: "Client-Centric Approach",
      description: (
        <>
          Our clients are at the heart of everything we do. We pride ourselves
          on our personalized service, ensuring that each client receives
          tailored solutions that cater to their specific requirements.
        </>
      ),
      delay: 300,
    },
    {
      icon: "bi-hand-thumbs-up",
      title: "Quality Assurance",
      description: (
        <>
          We are committed to maintaining the highest quality standards across
          all our products and services. Our partnerships with top brands in the
          lubricants industry guarantee that you receive only the best.
        </>
      ),
      delay: 400,
    },
    {
      icon: "bi-bag-heart",
      title: "Strong Client Relationships",
      description: (
        <>
          Our extensive portfolio includes esteemed clients such as{" "}
          <b>TAJ HOTEL, GANGA HOSPITAL, and PSG INDUSTRY</b>. We value long-term
          relationships built on trust and reliability.
        </>
      ),
      delay: 500,
    },
    {
      icon: "bi-currency-dollar",
      title: "Competitive Pricing",
      description:
        "We offer competitive pricing without compromising on quality, ensuring that you get the best value for your investment.",
      delay: 600,
    },
  ];

  return (
    <section className="services section light-background">
      <div className="container section-title" data-aos="fade-up">
        <h2>Why Choose Us</h2>
        <p>Building Strong Relationships Through Trust and Performance</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay={service.delay}
            >
              <div className="service-item position-relative">
                <div className="icon">
                  <i className={`bi ${service.icon}`}></i>
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
