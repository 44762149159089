import React from "react";

const contactDetails = [
  {
    icon: "bi-geo-alt",
    title: "Address",
    content: (
      <>
        90, Swarnambika Layout, Ram Nagar, <br />
        Coimbatore - 641 009.
      </>
    ),
    delay: 200,
  },
  {
    icon: "bi-telephone",
    title: "Call Us",
    content: "+91 95976 01333",
    delay: 300,
  },
  {
    icon: "bi-envelope",
    title: "Email Us",
    content: "kikerlubricants@gmail.com",
    delay: 400,
  },
  {
    icon: "bi-clock",
    title: "Opening Hours",
    content: (
      <>
        Monday - Saturday
        <br />
        10:00 AM - 6:00 PM
      </>
    ),
    delay: 500,
  },
];

export const ContactWidgets = () => {
  return (
    <>
      {contactDetails.map(({ icon, title, content, delay }, index) => (
        <div
          className="info-item d-flex"
          data-aos="fade-up"
          data-aos-delay={delay}
          key={index}
        >
          <i className={`bi ${icon} flex-shrink-0`}></i>
          <div>
            <h3>{title}</h3>
            <p>{content}</p>
          </div>
        </div>
      ))}
    </>
  );
};
