import React from "react";
import { Link, useLocation } from "react-router-dom";
import BASE_URLS from "../apiConfig";
import AboutBanner from "../Images/sub-banner.jpg";
import ProductsBanner from "../Images/products-banner.jpg";
import ClientsBanner from "../Images/clients-banner.jpg";
import ContactBanner from "../Images/contact-banner.jpg";

export const Subbanner = () => {
  const { pathname } = useLocation();
  const WebUrl = BASE_URLS.WebUrl;

  // Function to determine the page name and background image based on the route
  const getBannerDetails = (pathname) => {
    switch (pathname) {
      case `${WebUrl}about-us`:
        return { pageName: "About Us", backgroundImage: AboutBanner };
      case `${WebUrl}clients`:
        return { pageName: "Clients", backgroundImage: ClientsBanner };
      case `${WebUrl}contact-us`:
        return { pageName: "Contact Us", backgroundImage: ContactBanner };
      case `${WebUrl}products`:
        return { pageName: "Products", backgroundImage: ProductsBanner };
      default:
        return { pageName: "Page Not Found", backgroundImage: AboutBanner };
    }
  };

  const { pageName, backgroundImage } = getBannerDetails(pathname);

  return (
    <div
      className="page-title dark-background"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container position-relative">
        <h1>{pageName}</h1>
        <nav className="breadcrumbs">
          <ol>
            <li>
              <Link to={WebUrl}>Home</Link>
            </li>
            {pageName !== "Home" && <li className="current">{pageName}</li>}
          </ol>
        </nav>
      </div>
    </div>
  );
};
