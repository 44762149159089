import React from "react";
import BASE_URLS from "../../apiConfig.js";
import Slide1 from "../../Images/hero-banner.jpg";
import AboutProduct from "../../Images/about-product.PNG";
import { Link } from "react-router-dom";

const Banner = () => {
  const WebUrl = BASE_URLS.WebUrl;
  return (
    <section className="hero section dark-background">
      <img src={Slide1} alt="Welcome Banner" className="hero-bg" />

      <div className="container">
        <div className="row gy-5 justify-content-between">
          <div
            className="col-lg-6 order-lg-last hero-img text-center"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <img src={AboutProduct} className="img-fluid" alt="Our Products" />
          </div>

          <div
            className="col-lg-6  d-flex flex-column justify-content-center"
            data-aos="fade-in"
          >
            <h1>
              Fueling Excellence <br />
              <span>Across</span> Industries
            </h1>
            <p>We Are Recognized for Our Quality Trust Across Our Aera</p>
            <div className="d-flex">
              <Link to={`${WebUrl}products`} className="btn-get-started">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <svg
        className="hero-waves"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          ></path>
        </defs>
        <g className="wave1">
          <use href="#wave-path" x="50" y="3"></use>
        </g>
        <g className="wave2">
          <use href="#wave-path" x="50" y="0"></use>
        </g>
        <g className="wave3">
          <use href="#wave-path" x="50" y="9"></use>
        </g>
      </svg>
    </section>
  );
};

export default Banner;
