import React from "react";
import Lubricant from "../../Images/lubricant-industry.jpg";

const sectors = [
  {
    icon: "bi-hospital",
    title: "Hospitality Sector",
    description: (
      <>
        Operating premium hotels under the <b>"HOTEL GRAND PLAZA"</b> brand,
        providing exceptional experiences for corporate and leisure guests.
      </>
    ),
    delay: 400,
  },
  {
    icon: "bi-buildings",
    title: "Distribution and Trading",
    description:
      "Managing Bharat Petroleum outlets and supplying high-quality fuels and lubricants across Coimbatore.",
    delay: 300,
  },
  {
    icon: "bi-building-gear",
    title: "Industrial Lubricants",
    description:
      "Supplying top-tier lubricants from leading brands, catering to diverse industries with a focus on performance and efficiency.",
    delay: 500,
  },
];

const IconBox = ({ icon, title, description, delay }) => (
  <div
    className="icon-box d-flex position-relative"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <i className={`bi ${icon} flex-shrink-0`}></i>
    <div>
      <h4>
        <span className="stretched-link">{title}</span>
      </h4>
      <p>{description}</p>
    </div>
  </div>
);

export const LubricantIndustry = () => {
  return (
    <section className="alt-services section">
      <div className="container">
        <div className="row justify-content-around gy-5">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="about-img-left">
              <img
                src={Lubricant}
                className="img-fluid"
                alt="Lubricant Industry"
              />
            </div>
          </div>

          <div
            className="col-lg-5 d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3>Our Core Sectors</h3>
            <p>
              At VM Petro Products, we excel in delivering top-tier services
              across multiple sectors, with a commitment to quality,
              reliability, and customer satisfaction.
            </p>

            {sectors.map((sector, index) => (
              <IconBox
                key={index}
                icon={sector.icon}
                title={sector.title}
                description={sector.description}
                delay={sector.delay}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
