import React, { useEffect } from "react";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
import ActionImg from "../../Images/call-action.jpg";
import { Link } from "react-router-dom";
import BASE_URLS from "../../apiConfig.js";

export const CallAction = () => {
  const WebUrl = BASE_URLS.WebUrl;
  useEffect(() => {
    // Initialize the lightbox after the component is mounted
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    // Cleanup the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []);
  return (
    <section className="call-to-action section dark-background">
      <div className="container">
        <img src={ActionImg} alt="Call to action" />
        <div
          className="content row justify-content-center"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-xl-10">
            <div className="text-center">
              <Link to="" className="glightbox play-btn"></Link>
              <h3>Diesel Delivery at Your Doorstep</h3>
              <p>
                We service a wide range of industries, proudly catering to the
                top 50 clients with our reliable and efficient doorstep diesel
                delivery services. Our commitment to quality, timeliness, and
                customer satisfaction ensures that your business operations run
                smoothly with uninterrupted fuel supply.
              </p>
              <Link className="cta-btn" to={`${WebUrl}contact-us`}>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
