import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormData();
  };

  const sendFormData = () => {
    // Create a FormData object to match PHP's expected format
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("subject", formData.subject);
    formDataToSend.append("message", formData.message);
    formDataToSend.append("Submit", "true"); // To trigger the PHP 'isset' check

    // Send a POST request to the PHP API endpoint
    fetch("https://kikerlubricants.com/mail.php", {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse JSON response
      })
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message);
          setFormData({ name: "", email: "", subject: "", message: "" });
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while sending the message.");
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="php-email-form"
      data-aos="fade-up"
      data-aos-delay="200"
    >
      <h3>Send message</h3>
      <p>
        Get in touch with us effortlessly through the form below. Your thoughts
        and inquiries are important, and we’re here to respond promptly.
      </p>
      <div className="row gy-4">
        <div className="col-md-6">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-12">
          <textarea
            className="form-control"
            name="message"
            rows="4"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className="col-md-12 text-center">
          <button type="submit">Send Message</button>
        </div>
      </div>
    </form>
  );
};
