import React from "react";
import Banner from "./Banner";
import Clientslider from "./Clientslider";
import { Services } from "./Services";
import { HomeAbout } from "./HomeAbout";
import { LubricantIndustry } from "./LubricantIndustry";
import { CallAction } from "./CallAction";

export const Home = () => {
  return (
    <>
      <Banner />
      <HomeAbout />
      <Services />
      <LubricantIndustry />
      <CallAction />
      <Clientslider />
    </>
  );
};
