import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay } from "swiper/modules";
import backgroundImage from "../../Images/about-vision.jpg";

export const VisionMision = () => {
  const slides = [
    {
      title: "Our Vision",
      content:
        "Being one of the largest lubricant suppliers in South India, we aim to expand our business network across the globe within five years. The attainment of this goal is achieved through flawless services and customer satisfaction.",
    },
    {
      title: "Our Mission",
      content:
        "It is imperative to achieve customer satisfaction on quality. Our goal is to win the hearts of every customer by providing excellent service. Every day, we strive for excellence towards effective completion of our goal.",
    },
  ];

  return (
    <section className="why-us section">
      <div className="container">
        <div className="row g-0 border-radius-15">
          <div
            className="col-xl-5 col-md-5 img-bg"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src={backgroundImage} alt="Our Vision & Mission" />
          </div>
          <div
            className="col-xl-7 col-md-7 slides position-relative"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <Swiper
              modules={[Pagination, Autoplay]}
              speed={600}
              slidesPerView={1}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              pagination={{ dynamicBullets: true }}
              className="swiper"
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="item">
                    <h3 className="mb-3">{slide.title}</h3>
                    <p>{slide.content}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
