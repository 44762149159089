import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import No from "../src/Images/404.svg";
import { Link } from "react-router-dom";
import BASE_URLS from "./apiConfig";
import { Subbanner } from "./Components/Subbanner";

export const NotFound = () => {
  const WebUrl = BASE_URLS.WebUrl;
  return (
    <>
      <Subbanner />
      <section className="mt-5">
        <Container>
          <Row>
            <Col
              className="text-center"
              data-aos="zoom-out"
              data-aos-delay="100"
            >
              <img src={No} className="img-fluid" alt="Page Not Found" />
              <h1 className="pt-3">OOPS..!</h1>
              <p className="pb-3">
                The page you are looking for does not exist.
              </p>
              <Link className="btn-get-started" to={WebUrl}>
                Back to Home
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
